<template>
  <div class="upload-stats pt-1">
    <div>{{ $t('documents.upload.stats.progress', { completed, total }) }}</div>
    <div class="upload-stats__size">
      {{ formattedUploadedSize }} / {{ formattedTotalSize }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  completed: {
    type: Number,
    required: true
  },
  total: {
    type: Number,
    required: true
  },
  uploadedSize: {
    type: Number,
    required: true
  },
  totalSize: {
    type: Number,
    required: true
  }
})

const formattedUploadedSize = computed(() => {
  return window.vueInstance.$humanReadableSize(props.uploadedSize || 0)
})

const formattedTotalSize = computed(() => {
  return window.vueInstance.$humanReadableSize(props.totalSize || 0)
})
</script>

<style scoped>
.upload-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
}

.upload-stats__size {
  font-size: 12px;
  white-space: nowrap;
  text-align: right;
  margin-left: 8px;
}
</style>
